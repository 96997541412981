
import JnJuranetSearch from '@/views/search/JuranetSearch.vue';
import JnSearchResults from '@/views/search/SearchResults.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SearchData } from '@/models/search.model';
import { Testata } from '@/models/testata.model';
import {
  CategoriesJuranet,
  CategoriesNormativa,
  CategoriesGiurisprudenza,
  CategoriesPrassiJuranet,
} from '@/models/enums.model';
import { Sections, OrderingTypes } from '@/models/enums.model';
import { JnService } from '@/services/jn.service';
import MetaInfo from 'vue-meta';
import EventBus from '@/services/event.bus';
import { EsService } from '@/services/es.service';
import JnProgress from '@/views/common/Progress.vue';
import { Picklist } from '@/models/picklist.model';

@Component({
  components: {
    JnJuranetSearch,
    JnSearchResults,
    JnProgress
  },
  metaInfo(this: JnRealTime): MetaInfo {
    return { title: 'Juranews - Real Time' };
  },
})
export default class JnRealTime extends Vue {
  testata?: Testata;

  loading = false;

  normativaList = [
    new Picklist({
      label: 'Nazionale',
      value: CategoriesJuranet.DLS,
    }),
    new Picklist({
      label: 'Europea',
      value: CategoriesJuranet.NC,
    }),
    new Picklist({
      label: 'Internazionale',
      value: CategoriesJuranet.AI,
    }),
  ];
  categoriaNormativa = new Picklist({
    label: 'Nazionale',
    value: CategoriesJuranet.DLS,
  });

  tab = 0;

  searchDataNormativa = new SearchData({
    index: process.env.VUE_APP_JURANET_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    idCategory: [this.categoriaNormativa.value],
    size: 100,
    orderingType: OrderingTypes.DATA_GAZZETTA,
  });
  searchDataGiurisprudenza = new SearchData({
    index: process.env.VUE_APP_JURANET_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    size: 100,
    idCategory: CategoriesGiurisprudenza,
    orderingType: OrderingTypes.DATA_CREAZIONE,
  });
  searchDataPrassiDecisioni = new SearchData({
    index: process.env.VUE_APP_JURANET_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    size: 100,
    idCategory: CategoriesPrassiJuranet,
    orderingType: OrderingTypes.DATA_CREAZIONE,
  });

  async created() {
    this.loading = true;
    this.testata = JnService.getTestata(Sections.REAL_TIME);
    const docs = await EsService.getDocs(this.searchDataNormativa);
    EventBus.$emit('search-results', docs);
    this.loading = false;

    EventBus.$on('reset-real-time', () => {
      this.tab = 0;
      console.log('JnRealTime.created: resetting real time');
      //if (this.$route.path != '/realtime') {
      this.searchNormativa();
      //}
    });
  }

  async searchNormativa() {
    EventBus.$emit('search-results', []);
    this.loading = true;
    const docs = await EsService.getDocs(this.searchDataNormativa);
    EventBus.$emit('search-data', this.searchDataNormativa);
    EventBus.$emit('search-results', docs);
    this.loading = false;
  }

  async searchGiurisprudenza() {
    EventBus.$emit('search-results', []);
    this.loading = true;
    const docs = await EsService.getDocs(this.searchDataGiurisprudenza);
    EventBus.$emit('search-data', this.searchDataGiurisprudenza);
    EventBus.$emit('search-results', docs);
    this.loading = false;
  }

  async searchPrassiDecisioni() {
    EventBus.$emit('search-results', []);
    this.loading = true;
    const docs = await EsService.getDocs(this.searchDataPrassiDecisioni);
    EventBus.$emit('search-data', this.searchDataPrassiDecisioni);
    EventBus.$emit('search-results', docs);
    this.loading = false;
  }

  @Watch('categoriaNormativa')
  async changeCategoriaNormativa() {
    this.searchDataNormativa.idCategory = [this.categoriaNormativa.value];
    await this.searchNormativa();
  }
}
